<template>
  <div>
    <h1>Erste Schritte auf der Plattform</h1>
  </div>
</template>

<script setup lang="ts">

</script>

<style>

</style>